/** @format */
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";

import Skeleton from "../components/quickMatch/skeleton.component";
import { SourceDataCard } from "./quickMatch/sourceDataCard.component";
import { FieldCard } from "./quickMatch/fieldCard.component";
import { CandidateCards } from "./quickMatch/candidateCards.component";
import "../css/quickmatch.css";
import AuthorityMappingService from "../services/authorityMapping.service";
import CandidateService from "../services/candidate.service";
import JobService from "../services/job.service";
import SourceRecordService from "../services/sourceRecord.service";
import { getLabel, displayPercent } from "./helpers";
import { Tooltip } from "primereact/tooltip";
const AUTHORITYCONFIG = require("../assets/authority_config.json");
const HelpButtons = () => {
  const toast = useRef(null);

  const items = [
    {
      label: "Add",
      icon: "pi pi-pencil",
      command: () => {
        toast.current.show({
          severity: "info",
          summary: "Add",
          detail: "Data Added",
        });
      },
    },
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        toast.current.show({
          severity: "success",
          summary: "Update",
          detail: "Data Updated",
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        toast.current.show({
          severity: "error",
          summary: "Delete",
          detail: "Data Deleted",
        });
      },
    },
    {
      label: "React Website",
      icon: "pi pi-external-link",
      command: () => {
        window.location.href = "https://facebook.github.io/react/";
      },
    },
  ];
  return (
    <div>
      <Toast ref={toast} />

      <div className="card">
        <div className="speeddial">
          <SpeedDial
            model={items}
            radius={120}
            direction="up-left"
            type="quarter-circle"
            showIcon="pi pi-question"
          />
        </div>
      </div>
    </div>
  );
};

const KeyboardShortcutTemplate = () => {
  return (
    <ul>
      <li>
        <kbd>←</kbd>Select Previous Candidate
      </li>
      <li>
        <kbd>→</kbd>Select Next Candidate
      </li>
      <li>
        <kbd>↑</kbd>Full Match
      </li>
      <li>
        <kbd>↓</kbd>Partial Match
      </li>
      <li>
        <kbd>m</kbd>Manual Match
      </li>
      <li>
        <kbd>x</kbd>No Match Found
      </li>
    </ul>
  );
};

const KeyBoardShortcutPreview = ({ visible, setVisible }) => {
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Protip: Use Keyboard Shortcuts"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}>
        <KeyboardShortcutTemplate />
      </Dialog>
    </div>
  );
};

const rowToCard = (row) => {
  const cardRows = [];
  for (const [key, value] of Object.entries(row)) {
    if (key !== "recordId") {
      cardRows.push({
        value: value,
        property: key,
      });
    }
  }
  return cardRows;
};

const ReconciliationProgress = ({ value, totalRecords, count }) => {
  const _val = displayPercent(value, 0);
  const recordsLeft = totalRecords - count;
  const content =
    recordsLeft > 1
      ? `${recordsLeft} records left to vet!`
      : `Only ${recordsLeft} record left to vet!!!`;

  return (
    <>
      <Tooltip
        target={".progressBar"}
        content={content}
        mouseTrack
        mouseTrackLeft={10}
      />
      <div className="progressBar">
        <ProgressBar value={_val} />
      </div>
    </>
  );
};

const TitleTemplate = ({ sourceRecord, recordId, mapping }) => {
  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    if (mapping.length > 0 && sourceRecord && recordId) {
      //TODO: Need to check if these fields even exist in the mapping
      const authorField = mapping.filter(
        (x) => x.authorityFieldHeading === "author_Name",
      )[0].sourceFieldHeading;
      const titleField = mapping.filter(
        (x) => x.authorityFieldHeading === "work_Name",
      )[0].sourceFieldHeading;

      setAuthor(sourceRecord[authorField]);
      setTitle(sourceRecord[titleField]);
    }
  }, [sourceRecord, recordId, mapping]);

  if (!title || !author) {
    return <h2>Vetting Record #00{recordId}</h2>;
  } else {
    return (
      <h2>
        Vetting Record #00{recordId}: {title} by {author}
      </h2>
    );
  }
};

const SummaryDialog = ({ ref, visible, setVisible, counts }) => {
  console.log(counts);
  return (
    <Dialog
      ref={ref}
      onHide={() => {
        setVisible(false);
      }}
      visible={visible}
      id="overlay_panel"
      header="Reconciliation Summary"
      className="Dialog">
      <p>Congratulations on finishing the vetting process!</p>

      <div className="summary">
        <ul>
          <li>
            You vetted a total of{" "}
            {counts.full + counts.partial + counts.manual + counts.noMatch}{" "}
            Records
          </li>
          <li>You found {counts.full} full matches</li>
          <li>You found {counts.partial} partial matches.</li>
          <li>You made {counts.manual} manual matches.</li>
          <li>You were unable to find matches for {counts.noMatch} records.</li>
        </ul>
      </div>
      <div className="summaryActions">
        <Button
          label="Export Results"
          className="p-m-3 p-button p-d-block p-mx-auto"
          // ref={prevButton}
          icon="pi pi-download"
          onClick={() => {
            // ChangeRecord(-1);
            // prevButton.current.blur(); // removing focus
          }}
        />

        <Button
          label="New Request"
          className="p-m-3 p-button p-d-block p-mx-auto"
          icon="pi pi-plus"
          // ref={prevButton}
          onClick={() => {
            window.location = "/requests/create";
            // ChangeRecord(-1);
            // prevButton.current.blur(); // removing focus
          }}
        />
      </div>
    </Dialog>
  );
};

const IntroDialog = () => {
  const [visible, setVisible] = useState(true);

  return (
    <Dialog
      onHide={() => {
        setVisible(false);
      }}
      visible={visible}
      id="overlay_panel"
      header="Welcome to Quick Match"
      className="Dialog">
      <div className="intro">
        <p>Are you ready to reconcile some entities?</p>
        <p>
          <strong>Here's how it works:</strong>
          <ol>
            <li>
              We'll show you a record from your dataset and a list of candidates
              from the authority.
            </li>
            <li>
              You'll select the best match from the list of candidates if
              possible.
            </li>
            There's 4 different ways to do this:
            <ul>
              <li>
                <strong>Full Match</strong> - If the record is a perfect match
                for a candidate, select this option.
              </li>
              <li>
                <strong>Partial Match</strong> - If the record is a partial
                match for a candidate, select this option.
              </li>
              <li>
                <strong>Manual Match</strong> - If the record is not a match for
                any of the candidates, select this option.
              </li>
              <li>
                <strong>No Match Found</strong> - If the record is not a match
                for any of the candidates, select this option.
              </li>
            </ul>
            <li>We'll show you the next record.</li>
            <li>Repeat until you're done.</li>
            <li>Export your results.</li>
          </ol>
        </p>
      </div>
      <div className="shortcuts">
        <p>
          <strong>Pro tip: Use Keyboard Shortcuts</strong>
        </p>
        <KeyboardShortcutTemplate />
      </div>
    </Dialog>
  );
};

const RecordChangeButtons = ({
  prevButton,
  nextButton,
  changeRecord,
  isLastRecord,
}) => {
  if (isLastRecord) {
    return (
      <div className="record-change">
        <Button
          label="< Previous Record"
          className="p-m-3 p-button p-d-block p-mx-auto prev"
          ref={prevButton}
          onClick={() => {
            changeRecord(-1);
            prevButton.current.blur(); // removing focus
          }}
        />
      </div>
    );
  }

  return (
    <div className="record-change">
      <Button
        label="< Previous Record"
        className="p-m-3 p-button p-d-block p-mx-auto prev"
        ref={prevButton}
        onClick={() => {
          changeRecord(-1);
          prevButton.current.blur(); // removing focus
        }}
      />

      <Button
        label="Next Record >"
        ref={nextButton}
        className="p-m-3 p-button p-d-block p-mx-auto next"
        onClick={() => {
          changeRecord(1);
          nextButton.current.blur(); // removing focus
        }}
      />
    </div>
  );
};

const QuickMatch = ({ jobID }) => {
  const prevButton = useRef(null);
  const nextButton = useRef(null);
  const summaryDialog = useRef(null);

  const scrollContainerRef = useRef(null);

  const [summaryDialogVisible, setSummaryDialogVisible] = useState(false);

  const [jobName, setJobName] = useState(null);
  const [job, setJob] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [recordIndex, setRecordIndex] = useState(null);
  const [sourceDataRows, setSourceDataRows] = useState(null);
  const [sourceDataCols, setSourceDataCols] = useState(null);
  const [sourceRecord, setSourceRecord] = useState([]);

  const [allSourceRecords, setAllSourceRecords] = useState([]);
  const [sourceRecords, setSourceRecords] = useState(null);

  const [sourceDataRow, setSourceDataRow] = useState(null);

  const [candidates, setCandidates] = useState([]);
  const [allCandidates, setAllCandidates] = useState([]);

  const [headings, setHeadings] = useState([]);
  const [mappings, setMappings] = useState([]);

  const [heightPerHeading, setHeightPerHeading] = useState(null);

  const [progress, setProgress] = useState(0.0);

  const [loading, setLoading] = useState(true);
  const [keyboardShortcutVisible, setKeyboardShortcutVisible] = useState(false);

  const [matchCount, setMatchCount] = useState({
    full: 0,
    partial: 0,
    manual: 0,
    noMatch: 0,
  });

  useEffect(() => {
    // TODO: look of jobs where they might be a contributor
    JobService.get(jobID)
      .then((response) => {
        setJobName(response.data.name);
        setJob(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [jobID]);

  // LOAD data from Job only if the user is a creator or contributor of the dataset and that the job is ready
  useEffect(() => {
    async function fetchSourceData() {
      try {
        const data = await SourceRecordService.findByJobID(jobID);
        console.log(data.data);
        setAllSourceRecords(data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchSourceData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchAllCandidateData() {
      try {
        const data = await CandidateService.findByJob(jobID);

        setAllCandidates(data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchAllCandidateData();
  }, [jobID]);

  useEffect(() => {
    const filteredSourceRecords = allSourceRecords.filter((obj1) => {
      return allCandidates.some((obj2) => obj2.recordId === obj1.recordId);
    });

    setSourceRecords(filteredSourceRecords);
  }, [allCandidates, allSourceRecords]);

  // TODO: only get records that have candidates.
  useEffect(() => {
    if (sourceRecords) {
      const _rows = [];
      sourceRecords.forEach((element) => {
        const data = { ...element.data };
        data.recordId = element.recordId;
        _rows.push(data);
      });
      setSourceDataRows(_rows);
    }
  }, [sourceRecords]);

  useEffect(() => {
    if (sourceDataRows && sourceDataRows.length > 0) {
      setRecordId(sourceDataRows[0].recordId);
      setRecordIndex(0);
      setSourceRecord(rowToCard(sourceDataRows[0]));
      setSourceDataRow(sourceDataRows[0]);
    }
  }, [sourceDataRows]);

  useEffect(() => {
    if (recordIndex != null) {
      setSourceDataRow(sourceDataRows[recordIndex]);
      setSourceRecord(rowToCard(sourceDataRows[recordIndex]));
      setRecordId(sourceDataRows[recordIndex].recordId);
      const _progress = recordIndex / sourceDataRows.length;
      setProgress(_progress);
    }
  }, [recordIndex]);

  useEffect(() => {
    async function fetchCandidateData() {
      try {
        const data = await CandidateService.findByJobAndRecordID(
          jobID,
          recordId,
        );

        setCandidates(data.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (recordId) {
      fetchCandidateData();
    }
  }, [jobID, recordId]);

  useEffect(() => {
    async function fetchAuthorityMappings() {
      try {
        const data = await AuthorityMappingService.findByJobID(jobID);
        setMappings(data.data);
      } catch (error) {
        console.error(error);
      }
    }

    // AuthorityMappingService
    if (jobID && sourceRecord && candidates) {
      fetchAuthorityMappings();
    }
  }, [jobID, sourceRecord, candidates]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      // Whenever a new list is generated, set scroll position back to 0
      scrollContainerRef.current.scrollLeft = 0;
    }
  }, [candidates]);

  // NOTE: This logic may need to handle drastically different mappings per authority
  // Getting all headings for cards
  useEffect(() => {
    const _headings = [];
    const _ignoreHeadings = [];

    // Get all headings from candidates if there is a value
    const getCandidateHeadings = () => {
      const _candidateHeadings = [];
      candidates.forEach((element) => {
        for (const key in element.data) {
          if (
            element.data[key] !== "" &&
            key !== "unique_id" &&
            element.data[key].length > 0 &&
            key !== "authority"
          ) {
            _candidateHeadings.push(key);
          }
        }
      });

      // Sort the headings by the order in AUTHORITYCONFIG
      _candidateHeadings.sort((a, b) => {
        const fieldA = AUTHORITYCONFIG.fields[a];
        const fieldB = AUTHORITYCONFIG.fields[b];
        //TODO: need to handle the case that the fields don't exist in AUTHORITYCONFIG
        const orderA = fieldA?.order ?? 100; // Treat undefined as 0
        const orderB = fieldB?.order ?? 100; // Treat undefined as 0
        return orderA - orderB;
      });

      const uniqueCandidateHeadings = new Set(_candidateHeadings);
      return [...uniqueCandidateHeadings];
    };

    const getSourceHeadings = () => {
      const _sourceHeadings = [];

      sourceRecord.forEach((element) => {
        const val = element.property;
        if (
          !_ignoreHeadings.includes(val) &&
          val !== "unique_id" &&
          element.value !== ""
        ) {
          _sourceHeadings.push(val);
        }
      });
      return _sourceHeadings;
    };

    if (sourceRecord === null) {
      return;
    }

    // Getting unique headings from candidates (doing this first for sort order to apply)
    _headings.push(...getCandidateHeadings());

    // Adding headings from authMap
    const getMappedHeadings = () => {
      const _mappedHeadings = [];
      mappings.forEach((element) => {
        if (!_headings.includes(element.authorityFieldHeading)) {
          _mappedHeadings.push(element.authorityFieldHeading);
        }
        _ignoreHeadings.push(element.sourceFieldHeading);
      });
      return _mappedHeadings;
    };

    // filter sourceRecord for property that matches mapped headings if the value is not empty
    const _mappedHeadings = getMappedHeadings();
    sourceRecord.forEach((element) => {
      if (_mappedHeadings.includes(element.property) && element.value !== "") {
        _headings.push(element.property);
      }
    });

    // Getting unique headings from sourceData records
    _headings.push(...getSourceHeadings());

    // TODO: Review if needed
    const uniqueHeadings = new Set(_headings);
    setHeadings([...uniqueHeadings]);
  }, [mappings, jobID, sourceRecord, candidates]);

  useEffect(() => {
    const _heightPerHeading = {};

    if (
      headings === null ||
      candidates === null ||
      mappings === null ||
      sourceDataRow === null
    ) {
      return;
    }

    const getMaxHeightFromCandidates = (candidates) => {
      // let maxHeight = 0;
      candidates.forEach((candidate) => {
        for (const x in candidate.data) {
          if (!Array.isArray(candidate.data[x])) {
            continue;
          }

          const height = candidate.data[x].length;
          if (x in _heightPerHeading && height > _heightPerHeading[x]) {
            _heightPerHeading[x] = height;
          } else if (!(x in _heightPerHeading)) {
            _heightPerHeading[x] = height;
          }
        }
      });
    };

    const getMaxHeightFromSourceRecord = (mappings, sourceDataRow) => {
      // TODO: need to be able to handle multiple mappings to the same field
      // e.g. name 1 -> name 1, name 2 -> name 1 for multiple authorities
      const authorityFieldHeadings = [...mappings].map(
        (x, i) => x.authorityFieldHeading,
      );

      //remove duplicates in authorityFieldHeadings
      const uniqueAuthorityFieldHeadings = [...new Set(authorityFieldHeadings)];

      const getHeadingFromMapping = (heading) => {
        const fields = [];

        const values = [];

        mappings.forEach((element) => {
          if (element.authorityFieldHeading === heading) {
            fields.push(element.sourceFieldHeading);
          }
        });

        fields.forEach((element) => {
          if (element in sourceDataRow) {
            values.push(sourceDataRow[element]);
          }
        });

        return values.filter((item) => item);
      };

      for (const x in uniqueAuthorityFieldHeadings) {
        if (x in headings) {
          const srcRow = getHeadingFromMapping(uniqueAuthorityFieldHeadings[x]);
          const height = srcRow.length;

          if (
            uniqueAuthorityFieldHeadings[x] in _heightPerHeading &&
            height > _heightPerHeading[uniqueAuthorityFieldHeadings[x]]
          ) {
            _heightPerHeading[uniqueAuthorityFieldHeadings[x]] = height;
          } else if (!(uniqueAuthorityFieldHeadings[x] in _heightPerHeading)) {
            _heightPerHeading[uniqueAuthorityFieldHeadings[x]] = height;
          }
        }
      }
    };

    getMaxHeightFromCandidates(candidates);
    getMaxHeightFromSourceRecord(mappings, sourceDataRow);
    setHeightPerHeading(_heightPerHeading);

    setLoading(false);
  }, [headings, candidates, mappings, sourceDataRow]);

  const ChangeRecord = (direction) => {
    if (direction > 0) {
      if (recordIndex < sourceDataRows.length - 1) {
        setRecordIndex(recordIndex + 1);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        // TODO: End of reconciliation
        // setRecordIndex(0);
        setSummaryDialogVisible(true);
      }
    } else if (direction < 0) {
      if (recordIndex > 0) {
        setRecordIndex(recordIndex - 1);
      } else {
        setRecordIndex(sourceDataRows.length - 1);
      }
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (recordId) {
    return (
      <div className="quickmatch">
        <IntroDialog />
        <Button
          icon="pi pi-info-circle"
          rounded
          aria-label="Information"
          className="keyboard-info"
          onClick={() => setKeyboardShortcutVisible(true)}
        />
        <div className="pageTitle">
          <h1>QuickMatch for {jobName} dataset</h1>
          <TitleTemplate
            recordId={recordId}
            mapping={mappings}
            sourceRecord={sourceDataRow}
          />
        </div>
        <div className="records">
          <RecordChangeButtons
            prevButton={prevButton}
            nextButton={nextButton}
            changeRecord={ChangeRecord}
            isLastRecord={recordIndex === sourceDataRows.length - 1}
          />
          <ReconciliationProgress
            value={progress}
            count={recordIndex}
            totalRecords={sourceDataRows.length}
          />
          <div className="cards">
            <FieldCard headings={headings} heights={heightPerHeading} />
            <SourceDataCard
              rows={sourceDataRow}
              headings={headings}
              mapping={mappings}
              id={recordId}
              heights={heightPerHeading}
              toNextRecord={ChangeRecord}
            />
            <CandidateCards
              candidates={candidates}
              sourceRecord={sourceRecord}
              headings={headings}
              mapping={mappings}
              sourceRecordId={recordId}
              heights={heightPerHeading}
              toNextRecord={ChangeRecord}
              count={matchCount}
              setCount={setMatchCount}
              scrollRef={scrollContainerRef}
            />
          </div>
        </div>
        <KeyBoardShortcutPreview
          visible={keyboardShortcutVisible}
          setVisible={setKeyboardShortcutVisible}
        />
        {/* <HelpButtons /> */}

        <SummaryDialog
          visible={summaryDialogVisible}
          setVisible={setSummaryDialogVisible}
          counts={matchCount}
        />
      </div>
    );
  }
  return (
    <div className="quickmatch">
      <h1>
        Unable to use Quick Match due to insufficient matches (Job #{jobID})
      </h1>
    </div>
  );
};

export default QuickMatch;
