/** @format */

import { Tooltip } from "primereact/tooltip";
const AUTHORITYCONFIG = require("../../assets/authority_config.json");

export const getNamespace = (url) => {
  let namespaces = {
    viaf: "viaf",
    worldcat: "worldcat",
    wikidata: "wikidata",
    ulan: "ulan",
  };

  for (const key in namespaces) {
    if (url.includes(key)) {
      return namespaces[key];
    }
  }
  return;
};

export const getPrefixedName = (url) => {
  let ns = getNamespace(url);
  if (ns) {
    if (url[url.length - 1] === "/") {
      url = url.slice(0, -1);
    }
    var n = url.lastIndexOf("/");
    var suffix = url.substring(n + 1);
    return `${ns}:${suffix}`;
  }
  return url;
};

export const hyperlinkTemplate = (url) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {getPrefixedName(url)}
    </a>
  );
};

export const listTemplate = (list, links = null) => {
  list = list.filter((item) => item);
  if (list.length > 1) {
    return (
      <ul>
        {[...list].map((x, i) => {
          if (x.includes("http")) {
            return <li key={i}>{hyperlinkTemplate(x)}</li>;
          }
          if (links) {
            return <li key={i}> {labelledLinkTemplate(x, links[i])} </li>;
          }
          if (x !== "") {
            return <li key={i}>{x}</li>;
          }
          return null;
        })}
      </ul>
    );
  }
  return list[0];
};

export const labelledLinkTemplate = (label, url) => {
  const content = getPrefixedName(url);
  const labelID = content.replace(":", "");
  const labelTarget = `labelled-link-${labelID}`;

  return (
    <a href={url} className={labelTarget} target="_blank" rel="noreferrer">
      {/* <Tooltip
        target={"." + labelTarget}
        content={getPrefixedName(url)}
        mouseTrack
        mouseTrackLeft={10}
      /> */}
      {label}
    </a>
  );
};

export const ValueBodyTemplate = (data, links = null) => {
  var val = data;

  if (typeof val === "number") {
    return val;
  }

  if (Array.isArray(val)) {
    if (val.length === 0) {
      return "";
    }
    if (val.length > 1) {
      return listTemplate(val, links);
    }
    val = val[0];
  }
  if (typeof val === "string" && val.includes("http")) {
    return hyperlinkTemplate(val);
  }

  if (links) {
    return labelledLinkTemplate(val, links[0]);
  }

  return val;
};

export const displayPercent = (percent, fallback, digits = 2) =>
  percent === null || percent === undefined
    ? fallback
    : `${(percent * 100).toFixed(digits)}`;

export const getLabel = (x) => {
  if (x in AUTHORITYCONFIG.fields) {
    const fieldDescription = AUTHORITYCONFIG.fields[x].description;
    if (fieldDescription !== " ") {
      return (
        <>
          <Tooltip
            target={"." + x}
            content={fieldDescription}
            mouseTrack
            mouseTrackLeft={10}
          />
          <label className={`${x} tooltip`}>
            {AUTHORITYCONFIG.fields[x].label}
          </label>
        </>
      );
    } else {
      return (
        <label className={`${x}`}>{AUTHORITYCONFIG.fields[x].label}</label>
      );
    }
  }
  return x;
};
