/** @format */

import JobService from "../services/job.service";
import CandidateService from "../services/candidate.service";
import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import CountService from "../services/count.service";

/* TODO:
 * 1. Provide recommendations for the user to improve their request
 * 2. Provide a summary of the request
 * 3. Put summary in a table, possibly with a graph
 */

export default function JobInfo({ jobID, visible, onHide }) {
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [sourceRecordCounts, setSourceRecordCounts] = useState(0);
  const [candidateCounts, setCandidateCounts] = useState([]);
  const [matchCounts, setMatchCounts] = useState(0);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }

    async function fetchData() {
      const jobResponse = await JobService.get(jobID);
      setJob(jobResponse.data);

      const sourceRecordCountResponse = await CountService.getSourceRecordCount(
        jobID,
      );
      setSourceRecordCounts(sourceRecordCountResponse.data.count);

      const candidateResponse = await CandidateService.findByJob(jobID);
      setCandidates(candidateResponse.data);

      const matchCountResponse = await CountService.getMatchCount(jobID);
      setMatchCounts(matchCountResponse.data.count);
    }

    fetchData();
  }, [jobID]);

  useEffect(() => {
    if (candidates.length === 0) {
      return;
    }

    const results = Object.values(
      candidates.reduce((obj, item) => {
        obj[item.recordId] = obj[item.recordId] || {
          recordId: item.recordId,
          count: 0,
        };
        obj[item.recordId].count++;
        return obj;
      }, {}),
    );
    setCandidateCounts(results);
  }, [candidates]);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={`About Request: ${job.name}`}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onHide}>
        <p className="m-0">
          We processed {sourceRecordCounts} record
          {sourceRecordCounts > 1 ? "s" : ""} from your data.
        </p>
        <p className="m-0">
          We found {candidates.length} total candidate
          {candidates.length > 1 ? "s" : ""}.
        </p>
        <p className="m-0">
          We found candidates for only {candidateCounts.length} record
          {candidateCounts.length > 1 ? "s" : ""}.
        </p>

        <p className="m-0">
          You made {matchCounts} total match
          {matchCounts > 1 || matchCounts === 0 ? "es" : ""} for this request so
          far.
        </p>
      </Dialog>
    </div>
  );
}
