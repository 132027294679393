/** @format */

import JobService from "../services/job.service";
import CandidateService from "../services/candidate.service";
import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import NssiService from "../services/nssi.service";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import JobInfo from "./jobInfo.component.js";
import { Skeleton } from "primereact/skeleton";

const JobTable = () => {
  const navigate = useNavigate();
  let emptyItem = {
    id: null,
    createdAt: null,
    updatedAt: null,
    name: null,
    userId: null,
    status: null,
  };

  const toast = useRef(null);
  const [items, setItems] = useState(null);
  const [item, setItem] = useState(emptyItem);
  const [isLoading, setIsLoading] = useState(true);

  const [jobInfoVisible, setJobInfoVisible] = useState(false);
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("versd-keycloak-user")).sub,
  );

  useEffect(() => {
    // TODO: look of jobs where they might be a contributor
    // TODO: look up user roles
    JobService.findByUsername(userId)
      .then((response) => {
        const _items = [...response.data];
        for (const _item of _items) {
          // TODO: Review is it confusing to only update the status of non-ready jobs
          // if (_item.status !== "READY") {
          updateItem(_item, false);
          // }
        }
        setItems(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const formatDate = (timestamp) => {
    return (
      new Date(timestamp).toDateString() +
      " " +
      new Date(timestamp).toLocaleTimeString()
    );
  };

  const createTimeBodyTemplate = (rowData) => {
    return <>{formatDate(rowData.createdAt)}</>;
  };
  const updateTimeBodyTemplate = (rowData) => {
    return <>{formatDate(rowData.updatedAt)}</>;
  };

  const nameBodyTemplate = (rowData) => {
    return <>{rowData.name}</>;
  };
  const idBodyTemplate = (rowData) => {
    if (rowData.userId === userId) {
      return <>You</>;
    }

    // TODO: get info about user
    return <>{rowData.userId}</>;
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.status;
    if (rowData.status === "SUBMITTED") {
      status = "PROCESSING";
    }

    return (
      <>
        <span className={`status-badge status-${status.toLowerCase()}`}>
          {status}
        </span>
      </>
    );
  };

  const deleteItem = (data) => {
    const accept = () => {
      let _items = items.filter((val) => val.id !== data.id);
      setItems(_items);
      JobService.delete(data.id);
      toast.current.show({
        severity: "success",
        summary: "Delete",
        detail: `Request: '${data.name}' Deleted`,
      });
    };

    const reject = () => {
      console.log("Nothing deleted");
    };

    confirmDialog({
      message: `Do you want to delete '${data.name}'?`,
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
    });
  };

  const actionItems = (enabled, data) => {
    if (enabled) {
      return [
        {
          label: "Overview Results",
          icon: "pi pi-table",
          command: (e) => {
            navigate(`/requests/${data.id}/overview`, {
              state: { jobID: data.id },
            });
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: (e) => {
            setItem({ ...data });
            deleteItem(data);
          },
        },
        {
          label: "Export Results",
          icon: "pi pi-download",
          disabled: true,
          // TODO: add service to job or match for export
          // command: (e) => {
          //   window.location.href = "/requests/overview";
          // },
        },
        {
          label: "Share Job",
          icon: "pi pi-share-alt",
          disabled: true,
        },
        {
          label: "Info",
          icon: "pi pi-info-circle",
          command: (e) => {
            console.log(data);
            setItem({ ...data });
            setJobInfoVisible(true);
          },
        },
      ];
    }

    return [
      {
        label: "Delete",
        icon: "pi pi-times",
        command: (e) => {
          setItem({ ...data });
          deleteItem(data);
          toast.current.show({
            severity: "success",
            summary: "Delete",
            detail: "Request Deleted",
          });
        },
      },
    ];
  };

  const loadQuickMatch = (data) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Data Saved",
    });

    navigate(`/requests/${data.id}/quickmatch`, {
      state: { jobID: data.id },
    });
  };

  const getUpdate = (data, showToast) => {
    const status = data.status;
    const getNSSIjobStatus = async (ids) => {
      const statuses = [];

      for (const id of ids) {
        const response = await NssiService.getJobStatus(id);
        statuses.push(response.data);
      }
      return statuses;
    };

    const getNSSIjobResults = async (ids) => {
      const results = [];
      for (const id of ids) {
        const response = await NssiService.getJobResults(id);
        const data = [...response.data.data].flat(2);
        data.forEach((x) => (x.authority = response.data.metadata.authorityID));
        results.push(data);
      }
      return results.flat(2); //Flattening results across authorities, may want to unflatten
    };

    const nssiIDs = data.nssiJobId.split(",");

    getNSSIjobStatus(nssiIDs)
      .then((statuses) => {
        let _status = null;
        for (const element of statuses) {
          if (element.status === 404) {
            _status = "FAILED";
            break;
          } else if (element.status === "FAILED") {
            _status = "FAILED";
            break;
          } else if (element.status === "IN_PROGRESS") {
            _status = "PROCESSING";
            break;
          } else {
            _status = element.status;
          }
        }

        if (showToast) {
          if (_status !== status) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Updated Status Retrieved",
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "No update is available",
            });
          }
        }

        if (_status !== status && _status === "READY") {
          let hasCandidates = false;
          // Load Candidates
          getNSSIjobResults(nssiIDs).then((results) => {
            // TODO: handle no results: see https://gitlab.com/calincs/conversion/versd/-/issues/108
            results.forEach((candidate) => {
              const candidateData = {
                jobId: data.id,
                recordId: candidate.unique_id,
                matchProb: candidate.match_probability,
                data: candidate,
                authority: candidate.authority,
                authorName: candidate?.author_Name
                  ? candidate.author_Name[0]
                  : null,
                title: candidate?.work_Name ? candidate.work_Name[0] : null,
              };
              CandidateService.create(candidateData);
              hasCandidates = true;
            });

            if (!hasCandidates) {
              _status = "FAILED";
              toast.current.show({
                severity: "error",
                summary: "No Candidates found",
                life: 5000,
                detail:
                  "Unfortunately no candidates were found, please try a different authority or configuration for better results.",
              });
            }
          });
        }

        if (_status !== status) {
          data.status = _status;
          const date = new Date();
          data.updatedAt = date;
          JobService.update(data.id, data);
          setItem({ ...data });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateItem = (item, showToast = true) => {
    setIsLoading(true);
    setItem({ ...item });
    getUpdate(item, showToast);
  };

  const actionsBodyTemplate = (rowData) => {
    let enabled = true;
    const status = rowData.status;
    if (
      status === "SUBMITTED" ||
      status === "FAILED" ||
      status === "PROCESSING"
    ) {
      enabled = false;
    }

    if (!enabled) {
      return (
        <>
          <SplitButton
            label="Update"
            icon={
              isLoading && item.id === rowData.id
                ? "pi pi-refresh pi-spin"
                : "pi pi-refresh "
            }
            onClick={() => {
              updateItem(rowData);
              console.log(rowData);
            }}
            model={actionItems(enabled, rowData)}></SplitButton>
        </>
      );
    } else {
      return (
        <>
          <SplitButton
            label="Quick Match"
            icon="pi pi-link"
            onClick={() => {
              loadQuickMatch(rowData);
            }}
            model={actionItems(enabled, rowData)}></SplitButton>
        </>
      );
    }
  };

  const sortTimestamps = (event) => {
    let _items = [...items];

    if (event.order === 1) {
      _items.sort(function (x, y) {
        return (
          new Date(x[`${event.field}At`]) - new Date(y[`${event.field}At`])
        );
      });
    } else {
      _items.sort(function (y, x) {
        return (
          new Date(x[`${event.field}At`]) - new Date(y[`${event.field}At`])
        );
      });
    }

    return _items;
  };

  const emptyMessageTemplate = () => {
    return (
      <div className="empty-message">
        <p>No Requests found</p>

        <Button
          label="Create a request"
          onClick={() => {
            navigate("/requests/create", { replace: true });
          }}
        />
      </div>
    );
  };

  const JobTableTemplate = () => {
    return (
      <div className="card">
        <DataTable
          value={items}
          paginator
          rows={10}
          // header="Current Requests"
          removableSort
          sortField="created"
          emptyMessage={emptyMessageTemplate}
          sortOrder={-1}>
          <Column
            field="created"
            header="Created"
            body={createTimeBodyTemplate}
            sortable
            sortFunction={sortTimestamps}
            filterField="date"
          />
          <Column
            field="updated"
            header="Last Updated"
            body={updateTimeBodyTemplate}
            sortFunction={sortTimestamps}
            sortable
            dataType="date"
            filterField="date"
          />
          <Column field="name" header="Name" body={nameBodyTemplate} sortable />
          <Column
            field="user"
            header="Creator"
            body={idBodyTemplate}
            sortable
          />

          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
          />
          <Column field="actions" header="Actions" body={actionsBodyTemplate} />
        </DataTable>
      </div>
    );
  };

  const ColumnSkeletonTemplate = () => {
    return <Skeleton />;
  };

  const TableSkeleton = () => {
    const rows = Array.from({ length: 5 }, (v, i) => i);

    return (
      <div className="card">
        <DataTable value={rows}>
          <Column
            field="created"
            header="Created"
            body={ColumnSkeletonTemplate}
            sortable
            sortFunction={sortTimestamps}
            filterField="date"
          />
          <Column
            field="updated"
            header="Last Updated"
            body={ColumnSkeletonTemplate}
            sortFunction={sortTimestamps}
            sortable
            dataType="date"
            filterField="date"
          />
          <Column
            field="name"
            header="Name"
            body={ColumnSkeletonTemplate}
            sortable
          />
          <Column
            field="user"
            header="Creator"
            body={ColumnSkeletonTemplate}
            sortable
          />

          <Column
            field="status"
            header="Status"
            body={ColumnSkeletonTemplate}
            sortable
          />
          <Column
            field="actions"
            header="Actions"
            body={ColumnSkeletonTemplate}
          />
        </DataTable>
      </div>
    );
  };

  if (items === null) {
    console.log(items);
    return (
      <div>
        <h1>Current Requests</h1>
        <TableSkeleton />
      </div>
    );
  } else {
    return (
      <div>
        <Toast ref={toast}></Toast>
        <ConfirmDialog />
        <JobInfo
          jobID={item.id}
          visible={jobInfoVisible}
          onHide={() => setJobInfoVisible(false)}
        />
        <h1>Current Requests</h1>
        <JobTableTemplate />
      </div>
    );
  }
};

export default JobTable;
