/** @format */
import { getLabel, displayPercent } from "../helpers";
const AUTHORITYCONFIG = require("../../assets/authority_config.json");

export const FieldCard = ({ headings, heights }) => {
  headings = headings.filter((element) => {
    if (element in AUTHORITYCONFIG.fields) {
      return !("label field" in AUTHORITYCONFIG.fields[element]);
    } else {
      return true;
    }
  });

  return (
    <div className="fieldCard">
      <h3>Fields</h3>
      <hr />

      {[...headings].map((x, i) => (
        <div
          className={`card-row row-${i}`}
          key={i}
          style={{ height: `${x in heights ? heights[x] + 1 : "2"}em` }}>
          <strong>{getLabel(x)}</strong>
        </div>
      ))}
    </div>
  );
};
