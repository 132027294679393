/** @format */

import { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
import JobService from "../../services/job.service";
import UserRoleService from "../../services/userRole.service";
import NSSIService from "../../services/nssi.service";
import SourceRecordService from "../../services/sourceRecord.service";
import AuthorityMappingService from "../../services/authorityMapping.service";

import { useNavigate } from "react-router-dom";
import StepOne from "./stepOne.component";
import StepTwo from "./stepTwo.component";
import StepThree from "./stepThree.component";

function JobSubmissionForm() {
  let navigate = useNavigate();

  const [enabled, setEnabled] = useState([1]);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    setActiveIndex(Math.max(...enabled) - 1);
  }, [enabled]);

  const toast = useRef(null);

  const [jobName, setJobName] = useState("");
  useEffect(() => {
    if (sourceData.length !== 0) {
      setEnabled([1, 2]);
    }
  }, [jobName]);

  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    if (sourceData.length !== 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "File successfully uploaded!",
      });
      if (jobName !== "") {
        setEnabled([1, 2]);
      }
    }
  }, [sourceData]);

  const [maxMatch, setMaxMatch] = useState(1);
  const [matchThreshold, setMatchThreshold] = useState(0.8);
  const [autoMatch, setAutoMatch] = useState(false);
  const [authorities, setAuthorities] = useState([]);

  const [document, setDocument] = useState({});
  useEffect(() => {
    if (Object.keys(document).length > 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Authority Mappings saved",
      });
      setEnabled([1, 2, 3]);
    }
  }, [document]);

  const [jobID, setJobID] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("versd-keycloak-user")).sub,
  );

  useEffect(() => {
    console.group("Submission Form:");

    console.groupCollapsed("Handled:");
    console.log({ jobName });
    console.log({ sourceData });
    console.log({ authorities });
    console.log({ maxMatch });
    console.log({ matchThreshold });
    console.log({ autoMatch });
    console.log({ document });
    console.log({ mapping });
    console.log({ enabled });
    console.log({ jobID });
    console.groupEnd();

    console.log({ tableData }); //TODO: Remove if not being used
    console.log({ userId }); //TODO: load from keycloak

    console.groupEnd();
  }, [
    enabled,
    jobName,
    sourceData,
    jobID,
    tableData,
    mapping,
    maxMatch,
    matchThreshold,
    autoMatch,
    authorities,
    isSubmitted,
    document,
    userId,
  ]);

  const items = [
    {
      label: "Data",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Upload Data",
          detail: event.item.label,
        });
      },
    },
    {
      label: "Authorities",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Select Authority",
          detail: event.item.label,
        });
      },
    },
    {
      label: "Configuration",
      command: (event) => {
        toast.current.show({
          severity: "info",
          summary: "Configure Matches",
          detail: event.item.label,
        });
      },
    },
  ];

  /**
   * Processes job creation by creating a new job, user role, source records, and authority mappings.
   * @async
   * @function processJobCreation
   * @param {Object} jobData - The data for the job being created.
   * @param {Object} userRoleData - The data for the user role associated with the job.
   * @param {Object} nssiData - The data for the NSSI job IDs associated with the job.
   * @param {Object} document - The document associated with the job.
   * @returns {Promise<void>}
   */
  async function processJobCreation(jobData, userRoleData, nssiData, document) {
    try {
      // Retrieve NSSI job IDs
      const nssiJobIds = await getNSSIjobIds(nssiData, document);
      jobData.nssiJobId = nssiJobIds.toString();

      // Create a job and retrieve the response
      const response = await JobService.create(jobData);
      setJobID(response.data.id);

      // Set user role data and create user role
      userRoleData.jobId = response.data.id;
      await UserRoleService.create(userRoleData);

      // Create source records in parallel
      const createSourceRecords = sourceData.map(async (source, index) => {
        const recordData = {
          jobId: response.data.id,
          recordId: index.toString(),
          data: source,
        };
        await SourceRecordService.create(recordData);
      });
      await Promise.all(createSourceRecords);

      // Create authority mappings in parallel
      const createAuthorityMappings = [];
      for (const key in mapping) {
        mapping[key].forEach((element) => {
          const mappingData = {
            jobId: response.data.id,
            authority: key,
            authorityFieldHeading: element.authHeading,
          };

          if (Array.isArray(element.srcHeading)) {
            element.srcHeading.forEach((heading) => {
              const authMap = { ...mappingData };
              authMap["sourceFieldHeading"] = heading;
              createAuthorityMappings.push(
                AuthorityMappingService.create(authMap),
              );
            });
          } else {
            const authMap = { ...mappingData };
            authMap["sourceFieldHeading"] = element.srcHeading;
            createAuthorityMappings.push(
              AuthorityMappingService.create(authMap),
            );
          }
        });
      }
      await Promise.all(createAuthorityMappings);

      // Navigate to "/requests" after all promises have resolved
      navigate("/requests", { replace: true });
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }

  const getNSSIjobIds = async (data, doc) => {
    const nssiJobs = [];
    console.group("NSSI Job Submission");
    console.log({ doc });
    console.log({ data });

    for (const key in doc) {
      data["document"] = doc[key];
      data["context"]["authority"] = key;
      // data["context"]["authority"] = `${key}-sample`;
      console.log(data);

      const response = await NSSIService.submitJob(data);
      const jobID = response.data.jobId;
      nssiJobs.push(jobID);
    }
    console.log(nssiJobs);
    console.groupEnd();
    return nssiJobs;
  };
  const handleSubmit = (event) => {
    setIsSubmitted(true);
    event.preventDefault();

    console.group("Submitting to NSSI");

    const nssiData = {
      projectName: jobName,
      workflow: "reconciliation",
      format: "application/json",
      context: {
        matchNumber: maxMatch,
        matchThreshold: 0.0,
        // matchThreshold: matchThreshold,
      },
    };

    const jobData = {
      name: jobName,
      autoMatch: autoMatch,
      userId: userId,
      authorities: authorities.toString(),
      nssiJobId: "",
    };

    const userRoleData = {
      userId: userId,
      role: "CREATOR",
    };

    processJobCreation(jobData, userRoleData, nssiData, document);

    console.groupEnd();
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="text-700 text-center">
        <div className="text-900 font-bold text-2xl mb-3">
          <h1>Create Reconciliation Request</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <ScrollTop />
        <StepOne nameHandler={setJobName} dataHandler={setSourceData} />
        <StepTwo
          data={sourceData}
          enabled={enabled}
          authorityHandler={setAuthorities}
          dataHandler={setDocument}
          mappingHandler={setMapping}
        />
        <StepThree
          enabled={enabled}
          minMatchScoreHandler={setMatchThreshold}
          matchCountHandler={setMaxMatch}
          autoMatchHandler={setAutoMatch}
        />
        <div className="flex justify-content-center flex-wrap">
          <Button
            label="Submit"
            className="p-button flex align-items-center justify-content-center"
            icon={isSubmitted ? "pi pi-spinner pi-spin" : null}
            onClick={handleSubmit}
            disabled={enabled.includes(3) ? false : true}
          />
        </div>
        <Steps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
        />
      </form>
    </div>
  );
}

export default JobSubmissionForm;
