/** @format */

import React from "react";
import { Skeleton } from "primereact/skeleton";

const Loading = () => {
  return (
    <div className="quickmatch">
      <div className="pageTitle">
        <Skeleton width="18rem" height="3rem" className="mb-2" />
        <Skeleton width="36rem" height="2.5rem" className="mb-2" />
      </div>
      <div className="records">
        <div className="record-change">
          <Skeleton
            width="8em"
            height="2.5em"
            className="p-button p-component p-m-3 p-button p-d-block p-mx-auto prev"
          />
          <Skeleton
            width="8em"
            height="2.5em"
            className="p-button p-component p-m-3 p-button p-d-block p-mx-auto next"
          />
        </div>
        <div className="progressBar">
          <Skeleton width="60%" height="1.5em" />
        </div>

        <div className="cards">
          <Skeleton height="48em" width="80%" className="sourceCard"></Skeleton>
          <div className="candidates">
            <Skeleton
              height="48em"
              className="candidateCard "
              tabindex="-1"></Skeleton>
            <Skeleton
              height="48em"
              className="candidateCard "
              tabindex="-1"></Skeleton>
            <Skeleton
              height="48em"
              className="candidateCard "
              tabindex="-1"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
