/** @format */
import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ValueBodyTemplate } from "../helpers";
import { OverlayPanel } from "primereact/overlaypanel";

const AUTHORITYCONFIG = require("../../assets/authority_config.json");

// TODO: handle when heading is mapped to multiple fields
const EditSourceTemplate = ({ source, headings, overlayRef }) => {
  const [value, setValue] = useState("");
  const [srcData, setSrcData] = useState({});

  function handleChange(e) {
    setSrcData({
      ...srcData,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      {/* {[...headings].map((x, i) => (
        <div className="card-row" key={i}>
          {getValue(x)}
        </div>
      ))} */}
      <div className="card-row">
        <strong>Author URI: </strong>
        <InputText
          type="text"
          id={"authorURI"}
          name={"authorURI"}
          value={srcData["authorURI"]}
          onChange={handleChange}></InputText>
      </div>
      <div className="card-row">
        <strong>Work URI: </strong>
        <InputText
          type="text"
          id={"workURI"}
          name={"workURI"}
          value={srcData["workURI"]}
          onChange={handleChange}></InputText>
      </div>
      <div className="button-row">
        <Button
          icon="pi pi-check"
          className="p-m-3 p-button p-d-block p-mx-auto partial-match"
          autoFocus
          onClick={(e) => {
            overlayRef.current.toggle(e);
          }}
        />
        <Button
          icon="pi pi-times"
          className="p-m-3 p-button p-d-block p-mx-auto full-match"
          onClick={(e) => {
            overlayRef.current.toggle(e);
            // setSelectedCandidateIndex(i);
          }}
        />
      </div>
    </>
  );
};

export const SourceDataCard = ({
  rows,
  headings,
  mapping,
  id,
  heights,
  toNextRecord,
}) => {
  const [value, setValue] = useState("");

  const op = useRef(null);
  const toast = useRef(null);
  const isMounted = useRef(false);
  if (!(rows && headings && mapping)) {
    return;
  }

  const sourceFieldHeadings = [...mapping].map((x, i) => x.sourceFieldHeading);
  const authorityFieldHeadings = [...mapping].map(
    (x, i) => x.authorityFieldHeading,
  );

  const getHeadingFromMapping = (heading) => {
    const fields = [];
    const values = [];

    mapping.forEach((element) => {
      if (element.authorityFieldHeading === heading) {
        fields.push(element.sourceFieldHeading);
      }
    });

    fields.forEach((element) => {
      if (element in rows) {
        values.push(rows[element]);
      }
    });

    // TODO: trim list

    return ValueBodyTemplate(values);

    // return listTemplate(values);
  };

  const getValue = (heading) => {
    if (authorityFieldHeadings.includes(heading)) {
      return getHeadingFromMapping(heading);
    } else {
      return ValueBodyTemplate(rows[heading]);
    }
  };

  headings = headings.filter((element) => {
    if (element in AUTHORITYCONFIG.fields) {
      return !("label field" in AUTHORITYCONFIG.fields[element]);
    } else {
      return true;
    }
  });

  return (
    <div className="sourceCard">
      <h3>Source Record</h3>
      <hr />
      {[...headings].map((x, i) => (
        <div
          className={`card-row row-${i}`}
          key={i}
          style={{ height: `${x in heights ? heights[x] + 1 : "2"}em` }}>
          {getValue(x)}
        </div>
      ))}
      <div className="button-row">
        <Button
          label="Manual Match"
          className="p-m-3 p-button p-d-block p-mx-auto manual-match"
          onClick={(e) => op.current.toggle(e)}
        />
        <OverlayPanel
          ref={op}
          showCloseIcon
          id="overlay_panel"
          className="overlaypanel">
          <EditSourceTemplate
            source={rows}
            headings={headings}
            overlayRef={op}
          />
        </OverlayPanel>
      </div>
    </div>
  );
};
